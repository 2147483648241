import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import clonedeep from 'lodash.clonedeep';

import { NotificationService } from 'src/app/_core/services/notification.service';
import {
  Category,
  CategoryN1qlSearchResult,
  CategoryReduced,
  CdmChargeDescription,
  CdmTableData,
  ChargeDescription
} from 'src/app/_shared/models';
import { Utils } from 'src/app/_shared/utils';
import { NotificationType, TenantStandingList } from 'src/app/charge-cat/shared/enums';
import { messages } from 'src/app/charge-cat/shared/messages';
import {
  BulkChargeDescriptionListEdit,
  CategoryDeltaRequest,
  CategoryNameWithRuleTitlesList,
  CdmPageFilter,
  CdmTableResponse,
  ChargeDescriptionEdit,
  ChargeDescriptionListEdit,
  ChargeDescriptionSingleUpdateEdit,
  Tenant
} from 'src/app/charge-cat/shared/models';
import { UserState } from '../../_core/store/user/user.state';

import { Select } from '@ngxs/store';
import { BehaviorSubject, Observable, Subject, combineLatest, forkJoin, interval, of } from 'rxjs';
import { catchError, concatMap, exhaustMap, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { ApplicationInsightsService } from 'src/app/_core/services/app-insights-service.service';
import { ColumnFilter } from 'src/app/_shared/models/column-filter';
import { DataApiService } from 'src/app/charge-cat/services/data-api.service';
import { FunctionAppService } from 'src/app/charge-cat/services/function-app.service';
import { SubSink } from 'subsink';
import { EventBusService } from '../services/event-bus.service';
import { CategoryExt } from '../services/manual-in-ex-delta.service';
import { CatDictionary, CptHcpcsCodeDescription, RevenueCodeDescription } from './app/app-state.model';
import * as Actions from './app/app.actions';
import { ChargeCatSelectors } from './app/app.selectors';
import { FunctionAppJob } from './function-app-job/function-app-job-state.model';
import * as JobActions from './function-app-job/function-app-job.actions';
import { FunctionAppJobSelectors } from './function-app-job/function-app-job.selectors';
import { LoadingStateService } from './loading-state.service';

export interface CdmDict {
  [cdmItemDocId: string]: any;
}
export interface CatVersionDict {
  [versionDocId: string]: CategoryExt;
}

/**
 * **Charge Cat Facade**
 *
 * @description This facade is responsible for orchestrating
 * all NGXS store updates in Charge Cat.
 */
@Injectable({
  providedIn: 'root'
})
export class ChargeCatStoreFacade implements OnDestroy {
  readonly cdmTablePageSize = 10000;
  readonly exportPageSize = 10000;
  private editCategory$ = new Subject<Category>();
  private addCategory$ = new Subject<Category>();
  private updateCdmDataInElasticOnSuccess$ = new Subject<Category>();
  private _ppfDictionary$ = new BehaviorSubject<{ [categoryName: string]: string[] }>({}); // value: ppf.Name[]
  private fetchCategories$ = new Subject<boolean>(); // isRefresh is the boolean

  subs = new SubSink();
  hbCategoryIds = [];
  pbCategoryIds = [];
  drgvCategoryIds = [];
  ccCategoryIds = [];
  ppfDictionary$ = this._ppfDictionary$.asObservable();
  @Select(ChargeCatSelectors.selectedCategory) selectedCategory$: Observable<Category>;
  includesDict: CdmDict = {};
  excludesDict: CdmDict = {};
  versionsDict: CatVersionDict = {};
  getCategoryById$ = new Subject<{ docId: string; chargeCatId: string }>();

  constructor(
    private store: Store,
    private dataApiService: DataApiService,
    private notificationService: NotificationService,
    private eventBus: EventBusService,
    private functionAppService: FunctionAppService,
    private loadingStateService: LoadingStateService,
    private appInsightsService: ApplicationInsightsService
  ) {
    this.subscribeToJobPolling();
    this.subscribeToCdmDictionaryCreate();
    this.subscribeToEditCategory();
    this.subscribeToGetCategoryById();
    this.subscribeToAddCategory();
    this.subscribeToUpdateCdmDataInElasticOnSuccess();
    this.subscribeToFetchCategories();
  }

  ngOnDestroy() {
    this.subs?.unsubscribe();
  }

  private subscribeToJobPolling() {
    this.subs.sink = interval(10000)
      .pipe(
        exhaustMap(() => this.handleJobs()),
        catchError(() => of())
      )
      .subscribe();
  }

  private handleJobs() {
    const jobs = this.jobsSnapshot().filter(job => !job.isPaused);
    const jobStatusReqs = [];
    const jobsReqs: FunctionAppJob[] = [];
    if (jobs.length <= 0) {
      return of([]);
    }
    // filter out expired jobs and maintain list of running jobs
    jobs.forEach(job => {
      if (!this.hasJobRunTimeExpired(job)) {
        jobStatusReqs.push(this.checkSaveJobStatus([job.id, job.delId]));
        jobsReqs.push(job);
      }
    });
    // make job status requests
    if (jobStatusReqs.length <= 0) {
      return of([]);
    }
    return forkJoin(jobStatusReqs).pipe(
      map(res => {
        const cleanUpRequests: Observable<any>[] = [];

        res.forEach((r: string, i) => {
          if (!r) {
            this.notificationService.notify(`Job Failed for ${jobsReqs[i].category.name}`, NotificationType.Error);
            this.removeJobFromStore(jobsReqs[i].id);
          } else if (r === 'Processed') {
            cleanUpRequests.push(this.handleProcessedJob(jobsReqs[i]));
          } else if (r === 'Failed') {
            this.notificationService.notify(`Job Failed for ${jobsReqs[i].category.name}`, NotificationType.Error);
            this.removeJobFromStore(jobsReqs[i].id);
          } else if (r === 'VersionConflicts') {
            this.handleVersionConflicts(jobsReqs[i]);
          }
        });

        return cleanUpRequests;
      }),
      switchMap(cleanups => forkJoin(cleanups))
    );
  }

  private handleVersionConflicts(jobReq: FunctionAppJob) {
    console.log(`version conflicts on task for category: ${jobReq.category.name}`);
    let vcJob = clonedeep(jobReq);
    vcJob.isPaused = true;
    this.updateJobInStore(vcJob);
    setTimeout(() => {
      this.removeJobFromStore(jobReq.id);
      this.dataApiService.cancelElasticTask(jobReq.delId).subscribe();
      this.dataApiService.cancelElasticTask(jobReq.id).subscribe();
      this.updateCdmDataInElasticOnSuccess(jobReq.category);
    }, 60000); // backoff and retry later
  }

  private handleProcessedJob(jobReq: FunctionAppJob): Observable<any> {
    this.notificationService.notify(`Job completed for ${jobReq.category.name}`, NotificationType.Success);
    this.removeJobFromStore(jobReq.id);
    return this.functionAppService.couchbaseCopyAdfTrigger({ updatedTimestamp: jobReq.updatedTimestamp });
  }

  /**
   * Checks the full list of categories to see if a newly added category already exists.
   * @param categoryName - The name of the new category.
   * @returns boolean indicating if a duplicate was found.
   */
  isDuplicateCategoryName(categoryName: string): boolean {
    categoryName = categoryName.toLowerCase();
    return this.categoriesSnapshot().some((category: Category) => category?.name?.toLowerCase() === categoryName);
  }

  /**
   * Gets manual includes by the chargeCatId of the selected category.
   * @param chargeCatId - Id of the selected category.
   */
  fetchManualIncludes(category: Category) {
    const docIds = category.chargeDescriptions || [];
    if (!docIds.length) {
      this.store.dispatch(new Actions.SetManualIncludes([]));
      return;
    }
    this.subs.sink = this.dataApiService.fetchChargeDescriptionsByIds(docIds).subscribe(
      result => {
        if (result) {
          this.setManualIncludes(result);
        }
      },
      err => this.notificationService.notify(messages.error.fetchManualIncludes, NotificationType.Error, err)
    );
  }

  setManualIncludes(result?: ChargeDescription[]) {
    if (!result) {
      result = clonedeep(this.store.selectSnapshot(ChargeCatSelectors.manualIncludes));
    }
    const includes: ChargeDescription[] = clonedeep(result);
    if (includes) {
      includes.forEach(cdmItem => this.mapAdditionalGridColumns(cdmItem, true));
    }
    this.store.dispatch(new Actions.SetManualIncludes(includes));
  }

  fetchPpfsWithCategories() {
    this.dataApiService
      .fetchPpfsWithCategories()
      .pipe(tap(ppfDict => this._ppfDictionary$.next(ppfDict)))
      .subscribe();
  }

  private createVersionsArrayForDelta(versions: CategoryExt[], selectedCategory: CategoryExt) {
    let versionsAsc = versions.slice().reverse();

    const isLatest = this.isLatestCategory(versions, selectedCategory);
    //if latest add selectedCategory to versions array.
    if (isLatest) {
      versionsAsc.push(selectedCategory);
    }

    //find first version that has includes and exlcudes and chop array to start there
    const indexOfFirstManual = versionsAsc.findIndex(
      ver =>
        (ver.chargeDescriptions && ver.chargeDescriptions.length > 0) ||
        (ver.chargeDescriptionsExclusions && ver.chargeDescriptionsExclusions.length > 0)
    );
    versionsAsc = versionsAsc.slice(indexOfFirstManual);

    //Find current version and cut the array down to there to stop at the right place.
    const selectedVersionIdx = versionsAsc.findIndex(version => version.lastModified == selectedCategory.lastModified);
    if (selectedVersionIdx != -1 && selectedVersionIdx + 1 != versionsAsc.length) {
      versionsAsc = versionsAsc.slice(0, selectedVersionIdx + 1);
    }
    return versionsAsc;
  }

  /**
   * check if selected category latest version if not found assumption is you are on latest version
   * because the latest is not in the versions array
   * @param versions
   * @param selectedCategory
   * @returns
   */
  private isLatestCategory(versions: CategoryExt[], selectedCategory: CategoryExt): boolean {
    return !versions.some(ver => {
      return JSON.stringify(ver) === JSON.stringify(selectedCategory);
    });
  }

  /**
   * Gets manual excludes by the chargeCatId of the selected category.
   * @param chargeCatId - Id of the selected category.
   */
  fetchManualExcludes(category: Category) {
    const docIds = category.chargeDescriptionsExclusions || [];
    if (!docIds.length) {
      this.store.dispatch(new Actions.SetManualExcludes([]));
      return;
    }
    this.subs.sink = this.dataApiService.fetchChargeDescriptionsByIds(docIds).subscribe(
      result => {
        if (result) {
          this.setManualExcludes(result);
        }
      },
      err => this.notificationService.notify(messages.error.fetchManualExcludes, NotificationType.Error, err)
    );
  }

  setManualExcludes(result?: ChargeDescription[]) {
    result ??= clonedeep(this.store.selectSnapshot(ChargeCatSelectors.manualExcludes));
    const excludes: ChargeDescription[] = clonedeep(result);
    if(excludes){
      excludes.forEach(row => this.mapAdditionalGridColumns(row, true));
    }
    this.store.dispatch(new Actions.SetManualExcludes(excludes));
  }

  /**
   * Deletes a manual exclude by the docId.
   */
  deleteManualExclude(itemToRemove: ChargeDescriptionListEdit) {
    this.eventBus.setRemovingManualExclude(true);
    const selectedCategory = this.getLastestSelectedCat();
    const { name, userId } = this.store.selectSnapshot(UserState.userInfo);
    selectedCategory.modifiedBy = userId;
    itemToRemove.modifiedBy = userId;
    const idx = selectedCategory.chargeDescriptionsExclusions.findIndex(
      desc => desc === itemToRemove.chargeDescriptionIds[0]
    );
    selectedCategory.chargeDescriptionsExclusions.splice(idx, 1);
    this.appInsightsService.trackEvent({
      name: 'delete manual exclude',
      properties: {
        chargeDescriptionListEdit: itemToRemove,
        categoryName: selectedCategory.name,
        category: selectedCategory,
        user: { name, userid: userId }
      }
    });
    this.subs.sink = this.functionAppService.categoryDescriptionsManualRemove(itemToRemove).subscribe(
      (res: any) => {
        if (res) {
          requestAnimationFrame(() => {
            if (res && res[itemToRemove.chargeDescriptionIds[0]] && res[itemToRemove.chargeDescriptionIds[0]].Success) {
              selectedCategory.lastModified = res[itemToRemove.chargeDescriptionIds[0]].Success;
            }
            this.handleElasticDeleteManualExclude(selectedCategory, itemToRemove);
          });
        }
      },
      err => {
        this.notificationService.notify(messages.error.deleteManualExclude, NotificationType.Error, err);
        this.eventBus.setRemovingManualExclude(false);
        console.log('Error deleting manual exclude: ', err);
        this.appInsightsService.trackException({
          exception: err,
          error: new Error(
            `Error deleting manual exclude. Name: ${selectedCategory.name} Id: ${selectedCategory.docId}`
          )
        });
      }
    );
  }

  private getLastestSelectedCat(): Category {
    let sc: Category = clonedeep(this.getSelectedCategory());
    const catDic = clonedeep(this.getCategoryDictionary());
    let selectedCategoryFromDictionary = catDic[sc.docId];
    let selectedCategory = null;
    if (sc.lastModified && selectedCategoryFromDictionary.lastModified) {
      selectedCategory =
        sc.lastModified > selectedCategoryFromDictionary.lastModified ? sc : selectedCategoryFromDictionary;
    } else {
      selectedCategory = selectedCategoryFromDictionary;
    }
    return selectedCategory;
  }

  private handleElasticDeleteManualExclude(selectedCategory: Category, itemToRemove: ChargeDescriptionListEdit) {
    this.subs.sink = this.functionAppService
      .copyCategoryES2CB({ chargeCatId: [selectedCategory.docId], action: 'update' })
      .subscribe(
        res => {
          const newCatDictionary = clonedeep(this.getCategoryDictionary());
          newCatDictionary[selectedCategory.docId] = selectedCategory;
          this.store.dispatch([
            new Actions.UpdateCategoryAfterSave(selectedCategory),
            new Actions.SetCategoryDictionary(newCatDictionary),
            new Actions.SelectCategory(selectedCategory)
          ]);
          this.refreshCategoryAndVersions(selectedCategory.docId, selectedCategory.chargeCatId);
          this.updateElasticCdmAfterDeleteManualExclude(res, itemToRemove);
        },
        err => {
          this.notificationService.notify(messages.error.deleteManualExclude, NotificationType.Error, err);
          this.eventBus.setRemovingManualExclude(false);
          console.log('Error updating cdm category for delete manual exclude: ', err);
          this.appInsightsService.trackException({
            exception: err,
            error: new Error(
              `Error updating cdm category for delete manual exclude. Name: ${selectedCategory.name} Id: ${selectedCategory.docId}`
            )
          });
        }
      );
  }

  private updateElasticCdmAfterDeleteManualExclude(res: any, itemToRemove: ChargeDescriptionListEdit) {
    if (res.Status === 'Failed') {
      this.notificationService.notify(messages.error.deleteManualExclude, NotificationType.Error);
      console.log("Failed to update category in elastic: response status == 'Failed'");
      this.eventBus.setRemovingManualExclude(false);
    } else {
      requestAnimationFrame(() => {
        this.store.dispatch(new Actions.DeleteManualExclude(itemToRemove));
        this.eventBus.setRemovingManualExclude(false);
        this.notificationService.notify(messages.success.deleteManualException, NotificationType.Success);
        let updatedTimestamp = new Date().toISOString();
        // TODO: remove so we can have a flow that manages this instead of kicking off from the frontend
        this.subs.sink = this.functionAppService.couchbaseCopyAdfTrigger({ updatedTimestamp }).subscribe();
      });
    }
  }

  /**
   * Deletes a manual include by the docId.
   */
  deleteManualInclude(itemToRemove: ChargeDescriptionListEdit) {
    this.eventBus.setRemovingManualInclude(true);
    const selectedCategory = this.getLastestSelectedCat();
    const { name, userId } = this.store.selectSnapshot(UserState.userInfo);
    selectedCategory.modifiedBy = userId;
    itemToRemove.modifiedBy = userId;
    const idx = selectedCategory.chargeDescriptions.findIndex(desc => desc === itemToRemove.chargeDescriptionIds[0]);
    selectedCategory.chargeDescriptions.splice(idx, 1);
    this.appInsightsService.trackEvent({
      name: 'delete manual include',
      properties: {
        chargeDescriptionListEdit: itemToRemove,
        categoryName: selectedCategory.name,
        category: selectedCategory,
        user: { name, userid: userId }
      }
    });
    this.subs.sink = this.functionAppService.categoryDescriptionsManualRemove(itemToRemove).subscribe(
      (res: any) => {
        if (res) {
          requestAnimationFrame(() => {
            if (res && res[itemToRemove.chargeDescriptionIds[0]] && res[itemToRemove.chargeDescriptionIds[0]].Success) {
              selectedCategory.lastModified = res[itemToRemove.chargeDescriptionIds[0]].Success;
            }
            this.handleElasticDeleteManualInclude(selectedCategory, itemToRemove);
          });
        }
      },
      err => {
        this.notificationService.notify(messages.error.deleteManualInclude, NotificationType.Error, err);
        this.eventBus.setRemovingManualInclude(false);
      }
    );
  }

  private handleElasticDeleteManualInclude(selectedCategory: Category, itemToRemove: ChargeDescriptionListEdit) {
    this.subs.sink = this.functionAppService
      .copyCategoryES2CB({ chargeCatId: [selectedCategory.docId], action: 'update' })
      .subscribe(
        res => {
          const newCatDictionary = clonedeep(this.getCategoryDictionary());
          newCatDictionary[selectedCategory.docId] = selectedCategory;
          this.store.dispatch([
            new Actions.UpdateCategoryAfterSave(selectedCategory),
            new Actions.SetCategoryDictionary(newCatDictionary),
            new Actions.SelectCategory(selectedCategory)
          ]);
          this.refreshCategoryAndVersions(selectedCategory.docId, selectedCategory.chargeCatId);
          this.updateElasticCdmAfterDeleteManualInclude(res, itemToRemove);
        },
        err => {
          this.notificationService.notify(messages.error.deleteManualInclude, NotificationType.Error, err);
          this.eventBus.setRemovingManualInclude(false);
          this.appInsightsService.trackException({
            exception: err,
            error: new Error(
              `Error updating cdm category for delete manual include. Name: ${selectedCategory.name} Id: ${selectedCategory.docId}`
            )
          });
        }
      );
  }

  private updateElasticCdmAfterDeleteManualInclude(res: any, itemToRemove: ChargeDescriptionListEdit) {
    if (res.Status === 'Failed') {
      this.notificationService.notify(messages.error.deleteManualInclude, NotificationType.Error);
      this.eventBus.setRemovingManualInclude(false);
    } else {
      requestAnimationFrame(() => {
        this.store.dispatch(new Actions.DeleteManualInclude(itemToRemove));
        this.eventBus.setRemovingManualInclude(false);
        this.notificationService.notify(messages.success.deleteManualException, NotificationType.Success);
        let updatedTimestamp = new Date().toISOString();
        this.functionAppService.couchbaseCopyAdfTrigger({ updatedTimestamp: updatedTimestamp }).subscribe();
      });
    }
  }

  // /**
  //  * Gets the selected category by docId.
  //  * @param docId - DocId of the category selected in the category list.
  //  */
  selectCategory(docId: string, chargeCatId: string): Observable<Category> {
    if (!this.n1qlEditorHasPendingChanges()) {
      this.getCategoryById$.next({ docId, chargeCatId });

      return this.selectedCategory$.pipe(
        filter(cat => cat.docId === docId),
        take(1)
      );
    }
  }

  private subscribeToGetCategoryById() {
    this.subs.sink = this.getCategoryById$
      .pipe(
        switchMap(({ docId }) => this.dataApiService.getChargeCategoryById(docId)),
        filter(cat => !!cat),
        withLatestFrom(this.store.select(ChargeCatSelectors.allCategories)),
        tap(([cat, categories]) => {
          this.appInsightsService.trackEvent({
            name: 'category selected',
            properties: {
              docId: cat.docId,
              name: cat.name,
              category: cat
            }
          });
          const { rules } = categories.find(x => x.docId === cat.docId);
          cat.rules = rules;
          this.store.dispatch(new Actions.SelectCategory(cat));
        })
      )
      .subscribe();

    this.subs.sink = this.getCategoryById$
      .pipe(
        switchMap(({ chargeCatId }) => this.dataApiService.fetchCategoryVersions(chargeCatId)),
        tap((catVersions: Category[]) => this.eventBus.setCategoryVersions(catVersions))
      )
      .subscribe();
  }

  refreshCategoryAndVersions(docId: string, chargeCatId: string) {
    this.getCategoryById$.next({ docId, chargeCatId });
  }

  // /**
  //  * Gets the selected category by docId.
  //  * @param docId - DocId of the category selected in the category list.
  //  */
  selectCategoryVersion(catVersion: { category: Category; isLatest: boolean }) {
    if (catVersion) {
      this.store.dispatch(new Actions.SelectCategory(catVersion.category));
    }
  }

  /**
   * Deletes a category by chargeCatId.
   * @param chargeCatId - chargeCatId of the category being deleted.
   */
  deleteCategory(chargeCatId: string) {
    this.subs.sink = this.functionAppService
      .deleteCategory({ categoryId: chargeCatId })
      .pipe(
        switchMap(() =>
          // On success, delete it from the store and check if the category being
          // deleted was the selected category.
          this.functionAppService.copyCategoryES2CB({ chargeCatId: [chargeCatId], action: 'delete' })
        ),
        tap(() => {
          this.store.dispatch([
            new Actions.DeleteCategory(chargeCatId),
            new Actions.ResetSelectedCategoryIfDeleted(chargeCatId)
          ]);
          // Success message
          this.notificationService.notify(messages.success.deleteCategory, NotificationType.Success);
        }),
        catchError(err => {
          this.notificationService.notify(messages.error.deleteCategory, NotificationType.Error, err);
          return of();
        })
      )
      .subscribe();
  }

  /**
   * @Action Adds a new category.
   *
   * @description Adds the new category then re-sorts the list.
   * @note The DataAPI doesn't have an 'Add Category' controller action but instead uses 'Update Category' and inserts
   * a new record if there is no chargeCatId on the category passed to it. It then returns the new object.
   */
  addCategory(category: Category) {
    this.addCategory$.next(category);
  }

  private subscribeToAddCategory() {
    this.subs.sink = this.addCategory$
      .pipe(
        tap(category => {
          this.setModifiedByUser(category);
          category.versionEditLog = 'Category added';
        }),
        // do each add in the order they come in. Do not start the next until the
        // first add completes and app state is updated.
        concatMap(category => {
          return this.functionAppService.addCategory(category).pipe(
            switchMap((response: any) =>
              this.functionAppService
                .copyCategoryES2CB({
                  chargeCatId: [response[category.name].success.docId],
                  action: 'update'
                })
                .pipe(map(() => response))
            ),
            tap(response => {
              // Initialize the rules on the new category to an empty array
              response[category.name].success.rules = [];
              const newCategory = response[category.name].success;
              // Add new category to the list of categories, then sort
              const categoriesWithNewCategory = [newCategory, ...this.categoriesSnapshot()];
              const sortedCategories = this.sortCategories(categoriesWithNewCategory);

              // Update the categories in the store with the sorted list that includes the newly added one
              const { catDic, catDicHb, catDicPb, catDicDrgv, catDicCC } = this.getAllCategoryDictionaries();
              this.updateCategoryDictionaries(newCategory, catDic, catDicHb, catDicPb, catDicDrgv, catDicCC);
              this.store.dispatch(new Actions.AddCategory(sortedCategories, newCategory));
              this.notificationService.notify(messages.success.addCategory, NotificationType.Success);
              this.refreshCategoryAndVersions(newCategory.docId, newCategory.chargeCatId);
            })
          );
        }),
        catchError(err => {
          this.notificationService.notify(messages.error.addCategory, NotificationType.Error, err);
          return of();
        })
      )
      .subscribe();
  }

  editCategory(category: Category) {
    this.editCategory$.next(category);
  }

  private subscribeToEditCategory() {
    this.subs.sink = this.editCategory$
      .pipe(
        map(category => {
          category = Utils.omitDeep(category, '__typename');
          this.setModifiedByUser(category);
          return category;
        }),
        concatMap(category => {
          /**
           * fire both reqs in parallel using forkJoin
           * Any Edit requests that come in before it completes
           * will wait for completion and run seially.
           */
          return this.functionAppService.validateAndUpdateCategory(category).pipe(
            switchMap(() =>
              this.functionAppService.copyCategoryES2CB({
                chargeCatId: [category.docId],
                action: 'update'
              })
            ),
            tap(res => {
              if (res.status === 'Failed') {
                this.notificationService.notify(messages.error.editCategory, NotificationType.Error);
              } else {
                this.store.dispatch(new Actions.EditCategory(category));
                this.refreshCategoryAndVersions(category.docId, category.chargeCatId);
                this.notificationService.notify(messages.success.editCategory, NotificationType.Success);
              }
            }),
            catchError(err => {
              this.notificationService.notify(messages.error.editCategory, NotificationType.Error, err);
              return of();
            })
          );
        })
      )
      .subscribe();
  }

  translateN1qlToElastic() {
    let category = clonedeep(this.getCategoryForDeltaQuery());
    this.checkForCptListKeywordInN1QL(category);
    return this.functionAppService.translateN1QL(category.chargeDescriptionSelectorN1QL);
  }

  private checkForCptListKeywordInN1QL(category: Category) {
    if (category?.chargeDescriptionSelectorN1QL?.includes(Utils.cptListKeyword) && category.cptList?.length) {
      while (category.chargeDescriptionSelectorN1QL.includes(Utils.cptListKeyword)) {
        category.chargeDescriptionSelectorN1QL = category.chargeDescriptionSelectorN1QL.replace(
          Utils.cptListKeyword,
          JSON.stringify(category.cptList).replace(/"/g, "'")
        );
      }
    }
  }

  fetchElasticDelta(elasticQuery: CategoryDeltaRequest) {
    return this.dataApiService.fetchChargeCategoryDelta(elasticQuery);
  }

  private addJobToStore(jobId: string, delId: string, category: Category, updatedTimestamp) {
    const newJob = new FunctionAppJob();
    newJob.category = category;
    newJob.id = jobId;
    newJob.delId = delId;
    newJob.startDate = new Date();
    newJob.status = null;
    newJob.isPaused = false;
    newJob.updatedTimestamp = updatedTimestamp;
    this.store.dispatch(new JobActions.AddJob(newJob));
  }

  private updateJobInStore(job: FunctionAppJob) {
    this.store.dispatch(new JobActions.EditJob(job));
  }

  private removeJobFromStore(jobId: string) {
    this.store.dispatch(new JobActions.DeleteJob(jobId));
  }

  checkSaveJobStatus(jobIds: string[]) {
    return this.dataApiService.getElasticTaskStatus(jobIds);
  }

  checkActiveTenants(): void {
    this.dataApiService.fetchActiveRunningTenants().subscribe(t => {
      if (t.length > 0) {
        const tenantList = this.getTenantListSnapShot();
        const tenantFilter = tenantList.filter(tl => t.includes(tl.tenantName));
        const tenantDisplayNames = tenantFilter.map(t => t.displayName);
        this.store.dispatch(new Actions.setActiveRunningTenants(tenantDisplayNames));
      } else {
        this.store.dispatch(new Actions.setActiveRunningTenants([]));
      }
    });
  }

  private hasJobRunTimeExpired(job: FunctionAppJob) {
    let now = new Date();
    const diffTime = Math.abs((now as any) - (job.startDate as any));
    const diffMinutes = Math.ceil(diffTime / (1000 * 60));
    console.log(job.category.name + 'Time Running Minutes: ' + diffMinutes.toString());
    if (diffMinutes >= 20) {
      this.removeJobFromStore(job.id);
      this.notificationService.notify(
        `Charge descriptions not updated for ${job.category.name}. Job did not complete in allotted time.`,
        NotificationType.Error
      );
      return true;
    }
    return false;
  }

  saveN1QL() {
    this.loadingStateService.setIsSavingCategoryDelta(true);

    if (this.n1qlEditorHasPendingChanges()) {
      this.store.dispatch(new Actions.SetTempCategoryToSelectedCategory());
      this.store.dispatch(new Actions.SetModifiedByUserToSelectedCategory());
    }
    const user = this.store.selectSnapshot(UserState.userInfo);

    let selectedCategory = clonedeep(Utils.omitDeep(this.getSelectedCategory(), '__typename'));
    selectedCategory.versionEditLog = this.eventBus.versionEditLogSnapShot();
    this.checkForCptListKeywordInN1QL(selectedCategory);

    this.appInsightsService.trackEvent({
      name: 'Save N1QL from Category Definition Tab',
      properties: {
        userName: user.name,
        userId: user.userId,
        category: selectedCategory
      }
    });
    this.eventBus.setSavingN1QL(true);
    return (this.subs.sink = this.functionAppService
      .validateAndUpdateCategory(selectedCategory)
      .pipe(
        tap((category: Category) => {
          const { catDic, catDicHb, catDicPb, catDicDrgv, catDicCC } = this.getAllCategoryDictionaries();
          this.updateCategoryDictionaries(category, catDic, catDicHb, catDicPb, catDicDrgv, catDicCC);
          this.store.dispatch([
            new Actions.SelectCategory(category),
            new Actions.UpdateCategoryAfterSave(category),
            new Actions.N1QLEditorPendingChanges(false),
            new Actions.ClearTempCategory()
          ]);
        }),
        switchMap(() => this.updateCatInElasticOnSuccess(selectedCategory)),
        catchError(err => {
          this.loadingStateService.setIsSavingCategoryDelta(false);
          this.notificationService.notify(messages.error.saveN1QLStatement, NotificationType.Error, err);
          this.appInsightsService.trackException({
            exception: err,
            error: new Error(`Error saving category N1QL. Name: ${selectedCategory.name} Id: ${selectedCategory.docId}`)
          });
          return of();
        })
      )
      .subscribe()
      .add(() => {
        this.loadingStateService.setIsSavingCategoryDelta(false);
        this.eventBus.setSavingN1QL(false);
      }));
  }

  private updateCatInElasticOnSuccess(selectedCategory: any): Observable<any> {
    return this.functionAppService
      .copyCategoryES2CB({
        chargeCatId: [selectedCategory.docId],
        action: 'update'
      })
      .pipe(
        tap(res => {
          if (res.Status === 'Failed') {
            this.notificationService.notify(messages.error.saveN1QLStatement, NotificationType.Error);
            console.log("Failed to update category in elastic: response status == 'Failed'");
          } else {
            this.notificationService.notify(messages.success.saveN1QLStatement, NotificationType.Success);
            this.updateCdmDataInElasticOnSuccess(selectedCategory);
          }
        }),
        catchError(err => {
          this.notificationService.notify(messages.error.saveN1QLStatement, NotificationType.Error, err);
          this.appInsightsService.trackException({
            exception: err,
            error: new Error(
              `Error updating category in elastic. Name: ${selectedCategory.name} Id: ${selectedCategory.docId}`
            )
          });
          console.log('Failed to update category in elastic', err);
          return of();
        })
      );
  }

  private updateCdmDataInElasticOnSuccess(category: Category) {
    this.updateCdmDataInElasticOnSuccess$.next(category);
  }

  private subscribeToUpdateCdmDataInElasticOnSuccess() {
    this.subs.sink = this.updateCdmDataInElasticOnSuccess$
      .pipe(
        concatMap(category => {
          return this.functionAppService
            .updateCDMElastic({ chargeCatId: [category.chargeCatId], action: 'refresh' })
            .pipe(map(res => ({ res, category })));
        }),
        tap(({ res, category }) => {
          if (res.Status === 'Failed') {
            console.log("Failed to update CDM in elastic: response status == 'Failed'");
          }
          let taskId = res[0][`chargeCat-${category.chargeCatId}`].addTaskId;
          let delTaskId = res[0][`chargeCat-${category.chargeCatId}`].delTaskId;
          let updatedTimestamp = res[0][`chargeCat-${category.chargeCatId}`].updatedTimestamp;
          this.refreshCategoryAndVersions(category.docId, category.chargeCatId);
          this.addJobToStore(taskId, delTaskId, category, updatedTimestamp);
        }),
        concatMap(({ res, category }) => {
          let updatedTimestamp = res[0][`chargeCat-${category.chargeCatId}`].updatedTimestamp;
          return this.functionAppService.couchbaseCopyAdfTrigger({ updatedTimestamp });
        })
      )
      .subscribe();
  }

  private subscribeToFetchCategories() {
    this.subs.sink = this.fetchCategories$
      .pipe(
        withLatestFrom(this.store.select(ChargeCatSelectors.allCategories)),
        filter(([isRefresh, allCategories]) => isRefresh || allCategories?.length === 0),
        tap(() => this.loadingStateService.setLoadingCategories(true)),
        switchMap(() => this.getCategoriesAndRules()),
        map(categoriesWithRules => {
          this.store.dispatch(new Actions.SetCategories(categoriesWithRules));
          return categoriesWithRules.filter((cat: Category) => cat.rules.length > 0);
        }),
        tap(categoriesWithRulesOnly => {
          this.store.dispatch(new Actions.SetCategoriesWithRules(categoriesWithRulesOnly));
        }),
        catchError(err => {
          this.notificationService.notify(messages.error.fetchCategories, NotificationType.Error, err);
          this.appInsightsService.trackException({
            exception: err,
            error: new Error(`Error fetching categories`)
          });
          return of();
        }),
        tap(() => this.loadingStateService.setLoadingCategories(false))
      )
      .subscribe();
  }

  /**
   * Retrieves a sorted list of all categories with their associated rules.
   */
  async fetchCategories(refresh = false) {
    this.fetchCategories$.next(refresh);
  }

  searchAllCategoriesN1ql(n1qlQuery: string): Observable<CategoryN1qlSearchResult[]> {
    return this.dataApiService.searchAllCategoriesN1ql(n1qlQuery);
  }

  /**
   * Helper function for getting a snapsot of the SF jobs.
   */
  public jobsSnapshot(): FunctionAppJob[] {
    return this.store.selectSnapshot(FunctionAppJobSelectors.allJobs);
  }

  private getCategoryNameWithRuleTitlesList(): Observable<CategoryNameWithRuleTitlesList[]> {
    return this.dataApiService.getCategoryNameWithRuleTitlesList();
  }

  /**
   * Gets categories and rules and maps them together.
   */
  private getCategoriesAndRules(): Observable<CategoryReduced[]> {
    return forkJoin([this.getSortedCategories(), this.getCategoryNameWithRuleTitlesList()]).pipe(
      map(([categories, rules]) => {
        this.mapRulesToCategories(categories, rules);
        return categories;
      })
    );
  }

  /**
   * Gets and sorts all categories
   */
  private getSortedCategories(): Observable<CategoryReduced[]> {
    return this.dataApiService.fetchCategories();
  }

  /**
   * Maps rules associated with categories to each category.
   */
  private mapRulesToCategories(sortedCategories: CategoryReduced[], categoryRules: CategoryNameWithRuleTitlesList[]) {
    const catRuleDictionary = {};
    categoryRules.forEach(cat => {
      catRuleDictionary[cat.categoryName] = cat.ruleTitles;
    });
    const categoryDictionary = {};
    const categoryDictionaryHB = {};
    const categoryDictionaryPB = {};
    const categoryDictionaryDRGV = {};
    const categoryDictionaryCC = {};

    sortedCategories.forEach((category: Category) => {
      category.rules = [];
      category.displayName = category.name;
      categoryDictionary[category.docId] = category;
      if (category.name) {
        if (category.name.includes('HB')) {
          categoryDictionaryHB[category.docId] = category;
          categoryDictionaryCC[category.docId] = category;
          this.hbCategoryIds.push(category.docId);
          this.ccCategoryIds.push(category.docId);
        }
        if (category.name.includes('PO') && !category.name.includes('HB')) {
          categoryDictionaryPB[category.docId] = category;
          categoryDictionaryCC[category.docId] = category;
          this.pbCategoryIds.push(category.docId);
          this.ccCategoryIds.push(category.docId);
        }
        if (!category.name.includes('HB') && !category.name.includes('PO')) {
          categoryDictionaryDRGV[category.docId] = category;
          this.drgvCategoryIds.push(category.docId);
        }
        if (catRuleDictionary[category.name]) {
          category.rules = catRuleDictionary[category.name];
        }
      }
    });
    this.store.dispatch(new Actions.SetCategoryDictionary(categoryDictionary));
    this.store.dispatch(new Actions.SetCategoryDictionaryHB(categoryDictionaryHB));
    this.store.dispatch(new Actions.SetCategoryDictionaryPB(categoryDictionaryPB));
    this.store.dispatch(new Actions.SetCategoryDictionaryDRGV(categoryDictionaryDRGV));
    this.store.dispatch(new Actions.SetCategoryDictionaryCC(categoryDictionaryCC));
  }

  private updateCategoryDictionaries(
    category: Category,
    categoryDictionary: any,
    categoryDictionaryHB: any,
    categoryDictionaryPB: any,
    categoryDictionaryDRGV: any,
    categoryDictionaryCC: any
  ) {
    categoryDictionary[category.docId] = category;
    if (category.name.includes('HB')) {
      categoryDictionaryHB[category.docId] = category;
      categoryDictionaryCC[category.docId] = category;
      if (this.hbCategoryIds.indexOf(category.docId) == -1) {
        this.hbCategoryIds.push(category.docId);
      }
      if (this.ccCategoryIds.indexOf(category.docId) == -1) {
        this.ccCategoryIds.push(category.docId);
      }
    }
    if (category.name.includes('PO') && !category.name.includes('HB')) {
      categoryDictionaryPB[category.docId] = category;
      categoryDictionaryCC[category.docId] = category;
      if (this.pbCategoryIds.indexOf(category.docId) == -1) {
        this.pbCategoryIds.push(category.docId);
      }
      if (this.ccCategoryIds.indexOf(category.docId) == -1) {
        this.ccCategoryIds.push(category.docId);
      }
    }
    if (!category.name.includes('HB') && !category.name.includes('PO')) {
      categoryDictionaryDRGV[category.docId] = category;
      if (this.drgvCategoryIds.indexOf(category.docId) == -1) {
        this.drgvCategoryIds.push(category.docId);
      }
    }
    this.store.dispatch(new Actions.SetCategoryDictionary(categoryDictionary));
    this.store.dispatch(new Actions.SetCategoryDictionaryHB(categoryDictionaryHB));
    this.store.dispatch(new Actions.SetCategoryDictionaryPB(categoryDictionaryPB));
    this.store.dispatch(new Actions.SetCategoryDictionaryDRGV(categoryDictionaryDRGV));
    this.store.dispatch(new Actions.SetCategoryDictionaryCC(categoryDictionaryCC));
  }

  /**
   * Adds the 'modified by' user property to a modified category using the userState.
   * TODO: Find a way to get rid of this and to replace with setModifiedByUserToSelectedCategory action
   */
  private setModifiedByUser(category: Category) {
    const user = this.store.selectSnapshot(UserState.userInfo);
    if (user) {
      category.modifiedBy = user.userId;
    }
  }

  /**
   * If there is a tempCategory, which contains the new temporary N1QL statement
   * that is to be run prior to actually saving it, use it, otherwise use the selectedCategory.
   */
  private getCategoryForDeltaQuery(): Category {
    const tempCategory = this.store.selectSnapshot(ChargeCatSelectors.getTempCategory);
    return tempCategory ?? this.getSelectedCategory();
  }

  /**
   * Sorts the list of categories by name.
   * @param categories - Categories to sort.
   */
  private sortCategories(categories: Category[]): Category[] {
    return categories.sort((a, b) => {
      if (a.name && b.name) {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0;
      }
    });
  }

  /**
   * Helper function for getting a snapsot of the categories.
   */
  private categoriesSnapshot(): CategoryReduced[] {
    return this.store.selectSnapshot(ChargeCatSelectors.allCategories);
  }

  /**
   * Helper function for getting a snapsot of the isN1QLEditorDirty flag.
   */
  n1qlEditorHasPendingChanges(): boolean {
    return this.store.selectSnapshot(ChargeCatSelectors.isN1QLEditorDirty);
  }

  /**
   * Helper function for getting a snapsot of the selected category.
   */
  private getSelectedCategory() {
    return this.store.selectSnapshot(ChargeCatSelectors.selectedCategory);
  }

  /**
   * Retrieves a list of tenants used in the CDM table for filtering.
   */
  fetchTenantList() {
    const tenants = this.store.selectSnapshot(ChargeCatSelectors.tenantList);
    if (tenants.length === 0) {
      this.store.dispatch(new Actions.ToggleLoadingTenantList());
      this.subs.sink = this.dataApiService.fetchAllTenants().subscribe(
        tenantList => {
          const filteredTenants = this.filterValidTenantStandings(tenantList).sort((a, b) =>
            a.displayName > b.displayName ? 1 : -1
          );
          this.store.dispatch(new Actions.SetTenantList(filteredTenants));
        },
        err => {
          this.notificationService.notify(messages.error.fetchTenantList, NotificationType.Error, err);
          this.appInsightsService.trackException({
            exception: err,
            error: new Error(`Error getting tenant list.`)
          });
        }
      );
    }
  }

  /**
   * Checks each tenant record's standing property against the TenantStandingList enum.
   * @returns A list of tenants with valid standings.
   * @param tenants - The list of tenants to check.
   */
  public filterValidTenantStandings(tenants: Tenant[]): Tenant[] {
    return tenants.filter(tenant => Object.values(TenantStandingList).includes(tenant.standing));
  }

  /**
   * Retrieves a list of unique CPT used in the CDM table for filtering and on the Category Difinition for CPT mapping
   * to a category.
   */
  fetchHcpcsCptList() {
    const cptList = this.store.selectSnapshot(ChargeCatSelectors.cptList);
    if (cptList.length === 0) {
      this.store.dispatch(new Actions.ToggleLoadingCptList());
      return (this.subs.sink = this.dataApiService.fetchHcpcsStandard().subscribe(
        data => {
          const hcpcsCptList = clonedeep(data);
          hcpcsCptList.forEach(item => {
            this.mapCptHcpsDisplayName(item);
          });
          this.createAndStoreCptHcpcsDictionary(hcpcsCptList);
        },
        err => {
          this.notificationService.notify(messages.error.fetchCptList, NotificationType.Error, err);
          this.appInsightsService.trackException({
            exception: err,
            error: new Error(`Error getting HCPCS CPT list`)
          });
        }
      ));
    }
  }

  private createAndStoreCptHcpcsDictionary(data: any) {
    const cptHcpcsDictionary = this.store.selectSnapshot(ChargeCatSelectors.getCptHcpcsDictionary);
    if (Utils.isEmptyObject(cptHcpcsDictionary)) {
      const dictionary = Utils.createDictionaryFromArrayByKey(data, 'code');
      this.store.dispatch(new Actions.SetCptHcpcsDictionary(dictionary));
    }
  }

  /**
   * Retrieves a list of unique CPT used in the CDM table for filtering.
   *
   */
  fetchRevenueCodeList() {
    const revenueCodeList = this.store.selectSnapshot(ChargeCatSelectors.revenueCodeList);
    if (revenueCodeList.length === 0) {
      this.store.dispatch(new Actions.ToggleLoadingRevenueCodeList());
      this.subs.sink = this.dataApiService.fetchRevCodes().subscribe(
        revenueCodeList => {
          // const result = clonedeep(data);
          revenueCodeList.forEach(this.createRevenueCodeDisplayName());
          const revCodeDictionary = this.createRevCodeDictionary(revenueCodeList);
          this.store.dispatch(new Actions.SetRevenueCodeDictionary(revCodeDictionary));
          this.store.dispatch(new Actions.SetRevenueCodeList(revenueCodeList));
        },
        err => {
          this.notificationService.notify(messages.error.fetchRevenueCodeList, NotificationType.Error, err);
          this.appInsightsService.trackException({
            exception: err,
            error: new Error(`Error getting revenue code list.`)
          });
        }
      );
    }
  }

  /**
   * Retrieves a list of unique CPT used in the CDM table for filtering.
   *
   */
  fetchColumnUniqueFilterList(col: ColumnFilter, cdmPageFilter: CdmPageFilter) {
    return this.dataApiService.fetchCdmUniqueColumnData(cdmPageFilter, col.field, col.cbFilterField);
  }

  createRevCodeDictionary(revenueCodeList: RevenueCodeDescription[]) {
    let dictionary = {};
    revenueCodeList.forEach(rev => {
      dictionary[rev.code] = rev;
    });
    return dictionary;
  }

  applyCdmTableCategoryUpdate(updates: BulkChargeDescriptionListEdit) {
    this.eventBus.setCDMTableUpdatesLoading(true);
    const { cdmChargeDescriptions } = this.getCategoriesAndDescFromStore();

    const chargeDescriptionsToModify = updates.chargeDescriptionEdits
      .map(edit => cdmChargeDescriptions.find(c => c.docId === edit.chargeDescriptionId))
      .filter(docId => docId);

    const user = this.store.selectSnapshot(UserState.userInfo);
    updates.modifiedBy = user.userId;
    const categoryDocIds = this.getDistinctCategoriesFromBulkEdit(updates.chargeDescriptionEdits);

    this.subs.sink = this.functionAppService.updateCategoryDescriptionsManualApply(updates).subscribe(
      () => this.onSuccessOfManualApply(categoryDocIds, chargeDescriptionsToModify),
      err => this.onErrorOfManualApply(err)
    );
  }

  getDistinctCategoriesFromBulkEdit(chargeDescriptionEdits: ChargeDescriptionEdit[]) {
    const { categories } = this.getCategoriesAndDescFromStore();
    const categoriesToModify = chargeDescriptionEdits
      .reduce((cats, edit) => {
        const chrgDscCats = edit.categoryIdsForEdit.map(docId => categories.find(c => c.docId === docId));
        return cats.concat(chrgDscCats);
      }, [])
      .filter(docId => docId);

    return [...new Set<Category>(categoriesToModify)];
  }

  applyCdmTableCategorySingleUpdate(updates: ChargeDescriptionSingleUpdateEdit) {
    this.eventBus.setCDMTableUpdatesLoading(true);
    const { cdmChargeDescriptions, categories } = this.getCategoriesAndDescFromStore();

    let chargeDescriptionsToModify = updates.chargeDescriptionIds
      .map(docId => cdmChargeDescriptions.find(c => c.docId === docId))
      .filter(docId => docId);

    let categoriesToModify = [...updates.categoryIdsToAdd, ...updates.categoryIdsToDelete]
      .map(docId => categories.find(c => c.docId === docId))
      .filter(docId => docId);

    const user = this.store.selectSnapshot(UserState.userInfo);
    updates.modifiedBy = user.userId;

    this.subs.sink = this.functionAppService.updateCategoryDescriptionsManualApplySingle(updates).subscribe(
      () => this.onSuccessOfManualApply(categoriesToModify, chargeDescriptionsToModify),
      err => this.onErrorOfManualApply(err)
    );
  }

  private onErrorOfManualApply(err: any) {
    this.notificationService.notify(
      `${messages.error.applyCdmTableCategoryUpdate}: ${err.error}`,
      NotificationType.Error,
      err
    );
    this.eventBus.setCDMTableUpdatesLoading(false);
    console.log('Error applying cdm table category updates: ', err.error);
    this.appInsightsService.trackException({
      exception: err,
      error: new Error(`Error applying cdm table category updates.`)
    });
  }

  private onSuccessOfManualApply(
    categoriesToModify: CategoryReduced[],
    chargeDescriptionsToModify: CdmChargeDescription[]
  ) {
    this.store.dispatch(new Actions.ApplyCdmTableCategoryUpdate(categoriesToModify, chargeDescriptionsToModify));
    this.updateCatRequestChainFromCdmTable(categoriesToModify.map(category => category.docId));
  }

  private updateCatRequestChainFromCdmTable(categoryDocIds: string[]) {
    this.subs.sink = this.functionAppService
      .copyCategoryES2CB({ chargeCatId: categoryDocIds, action: 'update' })
      .subscribe(
        res => {
          setTimeout(() => {
            let updatedTimestamp = new Date().toISOString();
            this.functionAppService.couchbaseCopyAdfTrigger({ updatedTimestamp: updatedTimestamp }).subscribe(res => {
              console.log('couchbaseCopyAdfTrigger response: ', res);
              this.eventBus.setCDMTableUpdatesLoading(false);
            });
          }, 1000);
        },
        err => {
          console.log('Error updating category to elastic from cdm table: ', err);
          this.appInsightsService.trackException({
            exception: err,
            error: new Error(`Error updating category to elastic from cdm table.`)
          });
          this.eventBus.setCDMTableUpdatesLoading(false);
        }
      );
  }

  private getCategoriesAndDescFromStore() {
    const categories = this.categoriesSnapshot();
    const cdmChargeDescriptions = this.store.selectSnapshot<CdmTableData>(ChargeCatSelectors.cdmTableData).cdmData;
    return { cdmChargeDescriptions, categories };
  }

  getDefaultFilterParams(): CdmPageFilter {
    return {
      paging: {
        pageSize: this.cdmTablePageSize,
        orderByField: '',
        orderByDirection: ''
      },
      filters: [],
      doNotIncludeForServiceLine: []
    };
  }

  fetchCdmDataPaged(pageFilterParams: CdmPageFilter = null) {
    if (pageFilterParams === null) {
      // set defaults. We want to load this data in parallel with other initializations
      pageFilterParams = this.getDefaultFilterParams();
    }
    // needs to be after we set the default pageFilterParams when null
    if (this.hasFilterTermsExceededLimit(pageFilterParams)) {
      return;
    }

    this.subs.sink = this.dataApiService.fetchCdmDataPaged(pageFilterParams).subscribe(
      (res: CdmTableResponse) => {
        this.store.dispatch(new Actions.ClearCdmTableData());
        const cdmData = this.filterOutHiddenTenants(res.chargeDescriptions);
        this.store.dispatch(new Actions.SetCdmTableData({ cdmData, offset: 0 }));
        this.store.dispatch(new Actions.SetCdmDataPageCount({ cdmDataPagedCount: res.totalCount } as any)); //TODO: This shouldn't be necessary but subscriptions are watching "cdmDataPagedCount"
        this.eventBus.setCDMTableLoading(false);
      },
      err => {
        this.notificationService.notify(messages.error.fetchCdmPagedData, NotificationType.Error, err);
        this.eventBus.setCDMTableLoading(false);
        this.appInsightsService.trackException({
          exception: err,
          error: new Error(`Error fetching cdm data page. Params: ${JSON.stringify(pageFilterParams)}`)
        });
      }
    );
  }

  fetchCdmDataPagedExport(pageFilterParams: CdmPageFilter) {
    return (this.subs.sink = this.dataApiService.fetchCdmDataPagedExport(pageFilterParams).subscribe(
      (data: CdmChargeDescription[]) => {
        this.store.dispatch(new Actions.ClearCdmTableData());
        const cdmData = this.filterOutHiddenTenants(data);
        this.store.dispatch(new Actions.SetCdmTableData({ cdmData, offset: 0 }));
      },
      err => {
        this.notificationService.notify(messages.error.fetchCdmPagedData, NotificationType.Error, err);
        this.eventBus.setCDMTableLoading(false);
        this.appInsightsService.trackException({
          exception: err,
          error: new Error(`Error fetching cdm data page. Params: ${JSON.stringify(pageFilterParams)}`)
        });
      }
    ));
  }

  private filterOutHiddenTenants(cdmData: CdmChargeDescription[]): CdmChargeDescription[] {
    const tenantList = this.getTenantListSnapShot();

    const validTenantNames = tenantList
      .filter(x => Object.values(TenantStandingList).includes(x.standing))
      .map(x => x.tenantName);

    const validTenantsSet = new Set(validTenantNames);

    return cdmData.filter(x => validTenantsSet.has(x.tenantName));
  }

  private hasFilterTermsExceededLimit(pageFilterParams: CdmPageFilter) {
    let filterTermsCount = 0;
    pageFilterParams.filters.forEach(filter => {
      filterTermsCount += filter.selectedItems.length;
    });
    if (pageFilterParams.doNotIncludeForServiceLine) {
      filterTermsCount += pageFilterParams.doNotIncludeForServiceLine.length;
    }
    if (filterTermsCount >= 65536) {
      this.notificationService.notify(messages.error.maxTermsExceeded, NotificationType.Error);
      return true;
    }
    return false;
  }

  private subscribeToCdmDictionaryCreate() {
    this.subs.sink = combineLatest([this.selectedCategory$, this.eventBus.categoryVersions$])
      .pipe(tap(([selectCategory, versions]) => this.createDictionaries(selectCategory, versions)))
      .subscribe();
  }

  private createDictionaries(selectedCategory: Category, versions: Category[]): CategoryExt[] {
    let versionsToMutate = JSON.parse(JSON.stringify(versions));
    let versionsAsc = this.createVersionsArrayForDelta(versionsToMutate, selectedCategory);
    // make a dictionary of kv => key: cdmItem.docId, value: [...version.chargeDescriptions]
    // make a dictionary of kv => key: cdmItem.docId, value: [...version.chargeDescriptionsExclusions]
    const includesDict: CdmDict = {};
    const excludesDict: CdmDict = {};
    const versionsDict: CatVersionDict = {};

    // populate include / exclude dictionaries
    for (let i = 0; i < versionsAsc.length; i++) {
      const version = versionsAsc[i];
      // make versions dict
      versionsDict[i] = version;
      if (version.chargeDescriptions?.length) {
        for (const cdmDocId of version.chargeDescriptions) {
          if (!includesDict[cdmDocId]) {
            includesDict[cdmDocId] = { lastModified: version.lastModified, modifiedBy: version.modifiedBy };
          }
        }
      }

      if (version.chargeDescriptionsExclusions?.length) {
        for (const cdmDocId of version.chargeDescriptionsExclusions) {
          if (!excludesDict[cdmDocId]) {
            excludesDict[cdmDocId] = { lastModified: version.lastModified, modifiedBy: version.modifiedBy };
          }
        }
      }
    }
    this.includesDict = includesDict;
    this.excludesDict = excludesDict;
    this.versionsDict = versionsDict;
    return versionsAsc;
  }

  /**
   * Modifies CDM charge description tenant and category names for display.
   * @param chargeDescriptions A list of charge descriptions to modify.
   */
  public modifyCdmDataForDisplay(chargeDescriptions: CdmChargeDescription[], serviceLine: string) {
    chargeDescriptions.map(cdmItem => {
      this.mapCategoriesToCdmData(cdmItem, serviceLine);
      this.mapAdditionalGridColumns(cdmItem);
      this.mapTokenCommaList(cdmItem);
    });
  }

  mapTokenCommaList(cdmItem: CdmChargeDescription) {
    if (cdmItem.tokens && cdmItem.tokens.length > 0) {
      cdmItem.tokenCommaList = cdmItem.tokens.join(', ');
    }
  }

  getCategoryDictionary() {
    return this.store.selectSnapshot(ChargeCatSelectors.categoryDictionary);
  }

  getAllCategoryDictionaries(): {
    catDic: CatDictionary;
    catDicHb: CatDictionary;
    catDicPb: CatDictionary;
    catDicDrgv: CatDictionary;
    catDicCC: CatDictionary;
  } {
    const catDic = clonedeep(this.store.selectSnapshot(ChargeCatSelectors.categoryDictionary));
    const catDicHb = clonedeep(this.store.selectSnapshot(ChargeCatSelectors.categoryDictionaryHB));
    const catDicPb = clonedeep(this.store.selectSnapshot(ChargeCatSelectors.categoryDictionaryPB));
    const catDicDrgv = clonedeep(this.store.selectSnapshot(ChargeCatSelectors.categoryDictionaryDRGV));
    const catDicCC = clonedeep(this.store.selectSnapshot(ChargeCatSelectors.categoryDictionaryCC));
    return { catDic, catDicHb, catDicPb, catDicDrgv, catDicCC };
  }

  /**
   * Sets the values for the SI, APC (payment rate), and AMA Description columns.
   * @description If the charge description has a CPT or HCPCS value, it finds it in the
   * corresponding dictionary and grabs the needed values off of that (SI, APC, AMA Description),
   * then assigns them to the charge description.
   *
   * @param cdmItem - Current charge description
   * @param cptHcpcsDictionary - Lookup dictionary for the current charge description's HCPCS value
   */
  mapAdditionalGridColumns(cdmItem: ChargeDescription, addUserData?: boolean) {
    const cptHcpcsDictionary = this.store.selectSnapshot(ChargeCatSelectors.getCptHcpcsDictionary);
    let info: CptHcpcsCodeDescription = null;

    if (Utils.hasValue(cdmItem.cpt)) {
      info = cptHcpcsDictionary[cdmItem.cpt];
    }
    else if (Utils.hasValue(cdmItem.hcpcs)) {
      info = cptHcpcsDictionary[cdmItem.hcpcs];
    }

    if (info) {
      cdmItem.si = info.si;
      cdmItem.paymentRate = info.paymentRate;
      cdmItem.amaDescription = info.description;
    }
    if (addUserData) {
      this.handleUserManualInExDelta(cdmItem);
    }
    this.mapTenantDisplayName(cdmItem);
  }

  private handleUserManualInExDelta(cdmItem: ChargeDescription) {
    const modifiedByDictionary = this.eventBus.usersModifiedByVerDicSnapShot();
    const selectedCategory = this.getSelectedCategory();
    if (modifiedByDictionary && modifiedByDictionary[selectedCategory?.modifiedBy]) {
      const versions = this.eventBus.categoryVersionsSnapShot();
      if (versions.length == 0) {
        cdmItem.modifiedBy = modifiedByDictionary[selectedCategory.modifiedBy].name;
        cdmItem.lastModified = selectedCategory.lastModified;
      } else {
        const inclued = this.includesDict[cdmItem.docId];
        const exclued = this.excludesDict[cdmItem.docId];
        cdmItem.modifiedBy =
          modifiedByDictionary[inclued?.modifiedBy]?.name || modifiedByDictionary[exclued?.modifiedBy]?.name;
        cdmItem.lastModified = inclued?.lastModified || exclued?.lastModified;
      }
    } else {
      cdmItem.lastModified = selectedCategory.lastModified;
    }
  }

  /**
   * If the chargeDescription has a tenant, show the tenant's diplayname by mapping it to the item.
   * @param cdmItem - The selected item in the CDM grid.
   */
  private mapTenantDisplayName(cdmItem: ChargeDescription | CdmChargeDescription) {
    const tenantList = this.store.selectSnapshot(ChargeCatSelectors.tenantList);
    const tenant = tenantList.find(t => t.tenantName === cdmItem.tenantName);
    if (tenant && !cdmItem.tenantDisplayName) {
      cdmItem.tenantDisplayName = tenant.displayName;
    }
  }

  public getTenantListSnapShot() {
    return this.store.selectSnapshot(ChargeCatSelectors.tenantList);
  }

  /**
   * Maps associated categories to chargeDescriptions for displaying
   * in the CDM table Category column.
   *
   * @description Loops over the collection of chargeCategoryIds on a chargeDescription and
   * for each one, finds the category object.  The catgory name is then updated
   * to append the associated charge source to it.
   *
   * @param cdmItem - The current chargeDescription in the loop.
   * @param categories - The full list of categories.
   */
  private mapCategoriesToCdmData(cdmItem: CdmChargeDescription, serviceLine: string) {
    if (cdmItem.categoryNames === undefined) {
      cdmItem.categoryNames = '';
    }
    if (cdmItem.chargeCategoryIds) {
      let categoryDictionary = this.getServiceLineSpecificCategories(serviceLine);
      cdmItem.chargeCategoryIds.forEach((id: string, index: number) => {
        const category = categoryDictionary[id];
        if (category) {
          this.addCommasToCdmTableCategoryNames(id, cdmItem, category.name);
        }
      });
    }
  }

  public getServiceLineSpecificCategories(serviceLine: string) {
    if (serviceLine === 'all') {
      return this.store.selectSnapshot(ChargeCatSelectors.categoryDictionary);
    }
    if (serviceLine === 'hb') {
      return this.store.selectSnapshot(ChargeCatSelectors.categoryDictionaryHB);
    }
    if (serviceLine === 'pb') {
      return this.store.selectSnapshot(ChargeCatSelectors.categoryDictionaryPB);
    }
    if (serviceLine === 'drgv') {
      return this.store.selectSnapshot(ChargeCatSelectors.categoryDictionaryDRGV);
    }
    if (serviceLine === 'cc') {
      return this.store.selectSnapshot(ChargeCatSelectors.categoryDictionaryCC);
    }
  }

  /**
   * Adds a comma separator to multiple category names.
   *
   * @param id - The current chargeCategoryId of the selected charge description.
   * @param cdmItem - The selected item in the CDM grid.
   * @param modifiedCategoryName - The modifided category name.
   */
  private addCommasToCdmTableCategoryNames(id: string, cdmItem: CdmChargeDescription, modifiedCategoryName: string) {
    let separator = ', ';
    const isLastChargeCategoryId = id === cdmItem.chargeCategoryIds[cdmItem.chargeCategoryIds.length - 1];
    if (isLastChargeCategoryId) {
      separator = '';
    }
    if (!cdmItem.chargeCategoryCommaList) {
      cdmItem.chargeCategoryCommaList = '';
    }
    cdmItem.chargeCategoryCommaList += modifiedCategoryName + separator;
  }

  /**
   * Maps CPT and HCPCS codes and descriptions to a display name which combines both.
   * @example displayName = '1234 - Description - SI - Payment Rate'
   */
  private mapCptHcpsDisplayName(item): any {
    // Default the display name to `code - description`
    let displayName = `${item.code} - ${item.description}`;

    // If there is an SI but no Payment Rate, append the SI in parenthesis
    if (item.si !== '' && item.paymentRate === '') {
      displayName = `${displayName}  (${item.si})`;
    }

    // If there is an Payment Rate but no SI, append the Payment Rate in brackets
    if (item.si === '' && item.paymentRate !== '') {
      displayName = `${displayName} [${item.paymentRate}]`;
    }

    // If there is an Payment Rate and SI, append them both
    if (item.si !== '' && item.paymentRate !== '') {
      displayName = `${displayName} (${item.si}) [${item.paymentRate}]`;
    }
    item.displayName = displayName;
  }

  /**
   * Creates a display name for showing revenue codes in the CDM Table.
   */
  private createRevenueCodeDisplayName() {
    return (item: RevenueCodeDescription) =>
      (item.displayName = item.code + ' - ' + item.category + ' - ' + item.description);
  }
}
