import { Category, CategoryReduced, createChargeCategory } from 'src/app/_shared/models/category';
import { CdmChargeDescription, CdmTableData, ChargeDescription } from 'src/app/_shared/models/charge-description';
import { Tenant } from 'src/app/charge-cat/shared/models';

export class CptHcpcsCodeDescription {
  actualCopayments: string;
  code: string;
  description: string;
  displayName: string;
  minimumUnadjustedCopayment: string;
  nationalUnadjustedCopayment: string;
  paymentRate: string;
  relativeWeight: string;
  si: string;
}

export class RevenueCodeDescription {
  code: string;
  description: string;
  category: string;
  displayName: string;
}

export class ActiveRunningTenantsModel {
  tenants: String[];
}

export interface CatDictionary {[catDocId: string]: Category}

export class ChargeCatStateModel {
  categories: CategoryReduced[] = [];
  categoriesWithRules: CategoryReduced[] = [];
  categoryRules: any[];
  cdmChargeDescriptions: CdmChargeDescription[];
  cdmDataOffset: number;
  cdmTableData: CdmTableData;
  cdmDataPageCount: number;
  cptList: CptHcpcsCodeDescription[];
  hcpcsList: CptHcpcsCodeDescription[];
  cptHcpcsDictionary: any;
  isN1QLEditorDirty: boolean;
  revenueCodeList: RevenueCodeDescription[];
  revenueCodeDictionary: any;
  categoryDictionary: CatDictionary;
  categoryDictionaryHB: CatDictionary;
  categoryDictionaryPB: CatDictionary;
  categoryDictionaryDRGV: CatDictionary;
  categoryDictionaryCC: CatDictionary;
  selectedCategory: Category;
  selectedCategoryExcludes: ChargeDescription[];
  selectedCategoryIncludes: ChargeDescription[];
  tempCategory: Category;
  tenantList: Tenant[];

  static getDefaults() {
    return {
      categories: [],
      categoriesWithRules: [],
      categoryRules: [],
      cdmChargeDescriptions: [],
      cdmDataOffset: 0,
      cdmTableData: null,
      cdmDataPageCount: 0,
      cptList: [],
      hcpcsList: [],
      cptHcpcsDictionary: {},
      isN1QLEditorDirty: false,
      revenueCodeList: [],
      revenueCodeDictionary: {},
      categoryDictionary: {},
      categoryDictionaryHB: {},
      categoryDictionaryPB: {},
      categoryDictionaryDRGV: {},
      categoryDictionaryCC: {},
      selectedCategory: createChargeCategory(),
      selectedCategoryExcludes: [],
      selectedCategoryIncludes: [],
      tempCategory: null,
      tenantList: []
    };
  }
}
